<template>
    <CCard class="card-secondary card-outline">
        <CCardHeader>
            <div class="clearfix">
                <div class="float-left">
                    <h5 class="mb-0">Thông tin tồn kho</h5>
                </div>
            </div>
        </CCardHeader>
        <CCardBody>
            <form v-if="model != null">
                <div class="form-group row">
                    <span class="col-sm-4"><img :src="model.product.defaultPictureModel.imageUrl"
                            class="img-thumbnail rounded float-right" alt="Hình Đại Lý" /></span>
                    <span class="col-sm-8">
                        <span class="row store-title">{{ model.product.name }}</span>
                        <span class="row store-title-i">{{ model.agent.name }}</span>
                    </span>
                </div>
                <div class="form-group row">
                    <span class="col-sm-4 text-right">Số lượng sỉ:</span>
                    <span class="col-sm-2"><input class="form-control store-number"
                            v-model="values.wholesaleQuantity.quantity"></span>
                    <span class="col-sm-6 store-unit" v-if="values.wholesaleQuantity.unit != null">{{
                            values.wholesaleQuantity.unit.name
                    }}<span class="ml-1"><i>({{
        model.product.packagingSpecification
}})</i></span></span>
                </div>

                <div class="form-group row" v-if="!!errors['wholesaleQuantity.quantity']">
                    <div class="col-sm-4"></div>
                    <span class="col-sm-8 errors">
                        {{ errors["wholesaleQuantity.quantity"] }}
                    </span>
                </div>



                <div v-for="(item, index) in values.retailQuantities" :key="item.unitId">
                    <div class="form-group row">
                        <span class="col-sm-4 text-right" v-if="index == 0">Số lượng lẻ:</span>
                        <span class="col-sm-4 text-right" v-if="index > 0">&nbsp;</span>
                        <span class="col-sm-2"><input class="form-control store-number" v-model="item.quantity"></span>
                        <span class="col-sm-6 store-unit">{{ item.unit.name }}</span>
                    </div>

                    <div class="form-group row" v-if="!!errors[`retailQuantities[${index}].quantity`]">
                        <div class="col-sm-4"></div>
                        <span class="col-sm-8 errors">
                            {{ errors[`retailQuantities[${index}].quantity`] }}
                        </span>
                    </div>
                </div>
                <div class="form-group row">
                    <span class="col-sm-4 text-right">&nbsp;</span>
                    <span class="col-sm-8">
                        <button type="button" class="btn btn-success" @click="onUpdateProductInStock">Cập nhật</button>
                    </span>
                </div>
            </form>
        </CCardBody>
    </CCard>
</template>

<script>
import _ from 'lodash'
import * as yup from 'yup'

const editQtySchema = yup.object().shape({
    wholesaleQuantity: yup.object().shape({
        quantity: yup.number().min(0, "Số lượng chưa hợp lý")
    }),
    retailQuantities: yup.array().of(yup.object().shape({
        quantity: yup.number().min(0, "Số lượng chưa hợp lý")
    }))
});

export default {
    name: 'ProductQtyEdit',
    props: {
        model: {
            default: null,
            type: Object
        },
        onUpdateSuccess: { type: Function },
        onUpdateError: { type: Function },
    },
    data() {
        return {
            values: {
                wholesaleQuantity: {},
                retailQuantities: []
            },
            errors: {
                wholesaleQuantity: {
                    quantity: ''
                },
                retailQuantities: [{
                    quantity: ''
                }]
            }
        }
    },

    components: {
    },
    methods: {
        onlyNumbers(event) {
                let keyCode = event.keyCode ? event.keyCode : event.which;
                if (keyCode < 48 || keyCode > 57) {
                    // 46 is dot
                    event.preventDefault();
                }
            },
        validate(field) {
                editQtySchema.validateAt(field, this.values)
                    .then(() => this.errors[field] = '')
                    .catch((err) => {
                        this.errors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },
        async onUpdateProductInStock() {
            console.log("Begin update product in stock", this.values);
            try {
                await editQtySchema.validate(this.values, { abortEarly: false });
                this.errors = {};
            }
            catch (err) {
                this.errors = {};
                _.forEach(err.inner, error => {
                    this.errors[error.path] = error.message;
                    console.log("Validation error", { path: error.path, message: error.message })
                })
                console.log("Validation ALL ERROR", this.errors)
                return;
            }

            if (this.model != null) {
                try {
                    var inventoryItems = [this.values.wholesaleQuantity, ...this.values.retailQuantities];
                    var retVal = await this.$store.$productService.updateQtyInStock(this.model.product.id, this.model.agent.id, inventoryItems)
                    if (retVal.success) {
                        this.$store.dispatch('toast/displayNotification', { text: 'Cập nhật số lượng thành công.' }, { root: true });
                        if (this.onUpdateSuccess instanceof Function)
                            this.onUpdateSuccess({ productId: this.model.product.id, agentId: this.model.agent.id });
                    }

                }
                catch (err) {
                    this.$store.dispatch('alert/error', err.message, { root: true });
                    this.onUpdateError({ productId: this.model.product.id, agentId: this.model.agent.id, error: err });
                }
            }
        },
    },
    watch: {
        model: function (val) {
            console.log("BIND MODEL ON CHANGE", this.model);
            if (this.model != null) {

                this.values.wholesaleQuantity = {
                    quantity: this.model.wholesaleQuantity.availableQuantity,
                    availableQuantity: this.model.wholesaleQuantity.availableQuantity,
                    unitId: this.model.wholesaleQuantity.unitId,
                    unit: this.model.wholesaleQuantity.unit
                }

                this.values.retailQuantities = _.map(this.model.retailQuantities, (item) => {
                    return {
                        quantity: item.availableQuantity,
                        availableQuantity: item.availableQuantity,
                        unitId: item.unitId,
                        unit: item.unit
                    }
                })

                console.log("BUILD FORM MODEL", { wholesaleQty: this.values.wholesaleQuantity, retailQuantities: this.values.retailQuantities })
            }
        }
    }
}
</script>

<style scoped>
.store-title {
    font-weight: bold;
    font-size: 1.2rem;
}

.store-title-i {
    font-style: italic;
    font-size: 1rem;
}

.store-number {
    border: 0 none !important;
    border-bottom: 1px solid #999999 !important;
    text-align: right;
    font-weight: bold;
    border-radius: 0;
    color: #006622;
    height: 1.6em;
    padding: 0 5px;
}

.store-number:read-only {
    background: #ffffff;
    color: #333333;
    font-weight: bold;
}

.store-number:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

span.errors {
    color: red
}
</style>