<template>
    <CRow>
        <CCol sm="12">
            <TopHeadline headerTitle="Cập nhật số lượng trong kho" backTitle="Quay lại" :onGoBack="onGoBack">
            </TopHeadline>
            <CRow>
                <ProductQtyEdit :model="model" :onUpdateSuccess="onUpdateProductQtySuccess"
                    :onUpdateError="onUpdateProductQtyError"></ProductQtyEdit>
                <!-- <div class="col-sm-6 wrap-inventory">
                    <ProductQtyEdit :model="model" :onUpdateSuccess="onUpdateProductQtySuccess"
                    :onUpdateError="onUpdateProductQtyError"></ProductQtyEdit>
                </div> -->
            </CRow>

        </CCol>
    </CRow>

</template>

<script>
    import TopHeadline from '@/components/TopHeadline.vue'
    import ProductQtyEdit from '@/components/warehouses/ProductQtyEdit.vue'

    import { globalConfigs } from '@/global/index'
    console.log("Global", globalConfigs);

    import _ from 'lodash'
    import * as yup from 'yup'

    const distributionSchema = yup.object().shape({
        toQuantities: yup.array().of(yup.object().shape({
            quantity: yup.number().min(0,"Số lượng chưa hợp lý")
        })),
        fromQty: yup.number().min(1,"Số lượng xuất phải lớn hơn 0")
    });

    const editQtySchema = yup.object().shape({
        toQuantities: yup.array().of(yup.object().shape({
            quantity: yup.number().min(0,"Số lượng chưa hợp lý")
        }))
    });
    export default {
        name: 'InventoryEdit',
        data() {
            return {
                values: {
                    fromQty: 0,
                    toQuantities: []
                },
                errors: {
                    fromQty: '',
                    toQuantities: [{
                        quantity: ''
                    }]
                },


                model: null
            }
        },
        components: {
            TopHeadline,
            ProductQtyEdit
        },
        computed: {
        },
        methods: {
            onGoBack() {
                this.$router.push('/warehouses/inventorylist');
            },
            
            async loadData(productId, agentId) {
                this.model = await this.$store.$productService.editproductinventory(productId, agentId);
                console.log("RETURN DATA", this.model);
                if (this.model != null) {
                    this.values.toQuantities = _.map(this.model.retailQuantities, (item) => {
                        return {
                            quantity: 0,
                            unitId: item.unitId,
                            unit: item.unit
                        }
                    })
                    this.values.fromQty = 0

                    console.log("BUILD FORM MODEL", this.values.toQuantities)
                }
            },

           onUpdateProductQtySuccess (data) {
               console.log("Update Product Qty Success", data)
               //await this.loadData(this.model.product.id, this.model.agent.id)
           },

           onUpdateProductQtyError (data) {
               console.log("Update Product Qty Error", data)
           },
            
        },
        async mounted() {
            var p = this.$route.query.p;
            var ag = this.$route.query.ag;
            var productId = parseInt(p);
            var agentId = parseInt(ag);
            console.log('edit product in stock: ag & p', {ag,p})
            if (!isNaN(productId) && !isNaN(agentId)) {
                await this.loadData(productId,agentId)
            }
        },
    }
</script>

<style scoped>
    .rotating {
        transition: transform 1s ease-in-out;
    }

        .rotating:hover {
            transform: rotateZ(360deg);
        }

    .wrap-inventory {
        padding: 15px;
    }
    .store-title{
        font-weight: bold;
        font-size: 1.2rem;
    }
    .store-title-i {
        font-style: italic;
        font-size: 1rem;
    }
    .store-number {
        border: 0 none !important;
        border-bottom: 1px solid #999999 !important;
        text-align: right;
        font-weight: bold;
        border-radius: 0;
        color: #006622;
        height: 1.6em;
        padding: 0 5px;
    }
        .store-number:read-only{
            background: #ffffff;
            color: #333333;
            font-weight: bold;
        }
    .store-number:focus{
        outline: none;
        border: none;
        box-shadow: none;
    }
    span.errors {
        color:red
    }
</style>